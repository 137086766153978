/* nav open/close
*********************************************/
export function nav() {
	const navBtn = document.querySelector('.header__btn'),
				nav = document.querySelector('.nav'),
				logo = document.querySelector('.header__logo');
  
	navBtn.addEventListener('click', () => {
		navBtn.classList.toggle('is-opened');
		nav.classList.toggle('is-opened');
		document.body.classList.toggle('is-nav');

		if(nav.classList.contains('is-opened')){
			logo.setAttribute("src", logo.getAttribute("src").replace(/_off\./, '_on.'));
		}
	}, false);
}
