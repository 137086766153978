export function sliderSlick() {
	if ($(".mv__images").length) {
		$('.mv__images').slick({
			autoplay: true,
			autoplaySpeed: 6000,
			speed: 1000,
			pauseOnHover: false,
			fade: true,
			arrows: false,
		});
	}

	if ($(".top-results__list").length) {
		$('.top-results__list').slick({
	    autoplay: true,
	    autoplaySpeed: 0,
	    speed: 7000,
  		cssEase: 'linear',
  		arrows: false,
	    slidesToShow: 3.4,
	    slidesToScroll: 1,
			responsive: [{
	      breakpoint: 760,
	      settings: {
	        slidesToShow: 1,
          centerMode: true,
	      }
	    }]
		});
	}
}



