export function header() {
	let scroll;
	let mvHeight;

	const logo = document.querySelector('.header__logo');

	$(window).on('load resize', function(){
		if($('.mv').length){
			mvHeight = $('.mv__image').height() - 100;
		}

		if($('.page-mv').length){
			mvHeight = $('.page-mv').height() - 100;
		}

		if(matchMedia('(max-width: 760px)').matches){
			logo.setAttribute("src", logo.getAttribute("src").replace(/_off\./, '_on.'));
		}
	});

	$(window).on('scroll', function(){
		scroll = $(this).scrollTop();

		if(matchMedia('(min-width: 761px)').matches){
			if(scroll >= mvHeight) {
				$('.header').addClass('is-scroll');
				logo.setAttribute("src", logo.getAttribute("src").replace(/_off\./, '_on.'));
			} else {
				$('.header').removeClass('is-scroll');
				logo.setAttribute("src", logo.getAttribute("src").replace(/_on\./, '_off.'));
			}
		}
	});
}
